<template>
    <div class="h-100-cust px-5 py-4">
        <Loader v-if="loading" />
        <!-- Modal -->
        <ModalSelection
            v-bind:loading="loading"
            v-on:closeModal="addProject = false"
            display="Add New Project"
        />

        <ProjectHighlightsModal
            v-bind:loading="loading"
         />

        <b-row>
            <b-col xl="12" md="12" class="title my-4 text-center">
                {{
                    allProject.length === 0
                        ? "Create a New Project"
                        : "Please select a Project"
                }}
            </b-col>
            <b-col
                v-for="project of allProject"
                :key="project._id"
                sm="6"
                md="4"
                lg="3"
                xl="2"
                class="text-center"
            >
                <!-- <router-link to="table"> -->
                <b-card
                    @click="setProject(project)"
                    :title="project.project_name"
                    class="proj-card mb-4 cust-border"
                >
                <div @click.stop="setProjectStats(project)" class="icon-wrapper">
                    <img src="../assets/img/pie_chart_icon.svg"/>
                </div>
                <div @click.stop="openProjectCloneModal(project)" class="copy-wrapper">
                    <img style="max-width: 24px;" src="../assets/img/content_copy_icon.svg" />
                </div>
                </b-card>
                <!-- </router-link> -->
            </b-col>
        </b-row>
        <b-button pill v-b-modal.modal-add-project variant="primary" class="float"
            >Add New Project</b-button
        >
    </div>
</template>

<script>
import Swal from "sweetalert2";

import Loader from "../components/Loader";
import ModalSelection from "../components/SelectionModal";
import ProjectHighlightsModal from "../components/ProjectHighlightsModal";


export default {
    components: {
        Loader,
        ModalSelection,
        ProjectHighlightsModal
    },
    data() {
        return {
            allProject: [],
            modalOpen: false,
            loading: false,
        };
    },
    methods: {
        getAllProject() {
            // this.loading = true;
            this.axios
                .get("/getProjects")
                .then((res) => {
                    console.log("getProjects res", res);
                    this.allProject = res.data.projects;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("getProjects error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                // })
                // .finally(() => {
                //     this.loading = false;
                });
        },
        async setProject(project) {
            await this.$store.commit("setProject", project);
            await this.$root.$emit("setProjectDetails", project);
            await this.$store.commit("setHighlightsProject", project);
            this.$router.push("/table")
        },

        async setProjectStats(project){
            // e.preventDefault();
            await this.$store.commit("setHighlightsProject", project);
            this.$root.$emit('bv::show::modal', 'modal-project-highlights')
        },

        async openProjectCloneModal(project){
            console.log("openProjectCloneModal: ", project);

            let cloneProject = JSON.parse(JSON.stringify(project));
            cloneProject["project_name"] = project["project_name"] + " - Intv. project - " + this.getCurrentDate();
            await this.$store.commit("setCloneProject", cloneProject);

            this.$root.$emit('bv::show::modal', 'modal-clone-project');
        },
        getCurrentDate() {
            const date = new Date();
            const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero if needed
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month and pad with leading zero if needed
            const year = date.getFullYear(); // Get year

            return `${day}/${month}/${year}`; // Return date in "dd/mm/yyyy" format
        },

        async getAllActivitySubGroups() {
            await this.axios
                .get("/getAllActivitySubGroups")
                // .get("/getAllActivitySubGroups?project_id="+this.$store.getters.projectId)
                .then(async (res) => {
                    console.log("getAllActivitySubGroups  res", res);
                    let val = res.data.data;
                    await this.$store.commit("setAllActivitySubGroups", val);
                })
                .catch((error) => {
                    console.log("getAllActivitySubGroups error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    created: async function () {
        this.loading = true;

        await this.getAllProject();
        // await this.getAllActivitySubGroups();
    },
};
</script>

<style scoped lang="scss">
.h-100-cust {
    height: 100vh;
}

.float {
    position: fixed;
    bottom: 70px;
    right: 20px;
    z-index: 10;
}

.card {
    height: 159px;
    width: 191px;
    margin: auto;
}

.proj-card {
    :hover{
        cursor: pointer;
    }
    .card-body {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .card-title {
        font-size: 1.25em !important;
        margin-bottom: 0px !important;
        color: black;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        width: 100%;
        // overflow: hidden;
    }

    .icon-wrapper{
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 10px;
        z-index: 1;
    }
    
    .copy-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px;
        z-index: 1;
    }    
    
    .copy-wrapper{
        -webkit-transform:rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);        
    }
}

.cust-border {
    border-color: black !important;
    border-radius: 0px !important;
}

.step-3 {
    margin: 30px;
    min-height: 300px;
    height: 100%;
    width: 100%;
}

.title {
    font-weight: 400;
    font-size: 20px;
}

.select-activities {
    min-height: 250px;
    border-color: black;
    border: 1px solid #707070;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
}
.selected-sub-group {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    li {
        border: 1px solid #707070;
        border-radius: 5px;
        margin: 5px;
        padding: 0px 10px;
        color: #007cba;
        span {
            margin-left: 10px;
            color: #676161;
            cursor: pointer;
        }
    }
}
.select-activities.types {
    border-top: 0px;
    .selected-sub-group {
        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80px;
            margin: 10px 0;
            li.activity-type {
                border: 1px solid #707070;
                width: 72px;
                height: 76px;
                border-radius: 50%;
                margin: 0 auto;
                padding: 0px 10px;
                color: #676161;
                font-size: 40px;
            }
            span {
                margin-left: 10px;
                color: #676161;
                cursor: pointer;
                font-size: 12px;
                text-align: center;
            }
        }
    }
}
.accord-position {
    left: 45px;
    top: 7px;
}
</style>

<style lang="scss">
.modal-content {
    border-radius: 31px !important;
}
.btn.dropdown-toggle.btn-primary {
    min-width: 250px !important;
    max-width: 250px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.btn.dropdown-toggle.btn-light {
    min-width: 250px !important;
}
.modal-lg {
    max-width: 1050px !important;
}
.modal-title {
    margin-left: auto;
    font-size: 20px;
    color: black;
    font-weight: 400;
}
.blue {
    background-color: #b0e1e9; //original
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>